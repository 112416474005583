<template>
    <div class="DeviceInfoList">
        <el-card class="box-card">
            <div slot="header" class="clearfix">
                <el-form :inline="true" class="demo-form-inline" :model="deviceForm" ref="deviceForm">
                    <el-form-item label="仪器名称">
                        <el-input v-model="deviceForm.deviceName" placeholder="请输入仪器名称查询"></el-input>
                    </el-form-item>
                    <el-form-item label="仪器型号">
                        <el-input v-model="deviceForm.deviceType" placeholder="请输入仪器型号查询"></el-input>
                    </el-form-item>
                    <el-form-item label="仪器编号">
                        <el-input v-model="deviceForm.deviceNum" placeholder="请输入仪器编号查询"></el-input>
                    </el-form-item>
                    <el-form-item label="仪器分类">
                        <el-select placeholder="请选择仪器仪表分类" v-model="deviceForm.deviceOneClass">
                            <el-option v-for="(item, index) in deviceOneClassList" :key="index" :label="item.subClassDesc"
                                :value="item.subClassCode"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-button  class="btn" @click="handleSearch" icon="el-icon-search">查询</el-button>
                        <el-button @click="handleReset" icon="el-icon-refresh-right">重置</el-button>
                    </el-form-item>
                </el-form>
            </div>

            <!-- 列表 -->
            <el-table :data="deviceList" border  style="width: 100%; font-size: 13px;"
                :header-cell-style="{ background: '#eee', fontWeight: 700, color: '#606266' }">
                <el-table-column prop="seq" label="序号" align="center" width="50px">
                </el-table-column>
                <el-table-column prop="deviceOneClassDesc" label="仪器分类" align="center"> </el-table-column>
                <el-table-column prop="deviceName" label="仪器名称" align="center"> </el-table-column>
                <el-table-column prop="deviceRealName" label="实际名称" align="center">
                </el-table-column>
                <el-table-column prop="deviceCode" label="仪器条码" align="center">
                </el-table-column>
                <el-table-column prop="deviceType" label="仪器型号" align="center">
                </el-table-column>
                <el-table-column prop="deviceNum" label="仪器编号" align="center">
                </el-table-column>
                <el-table-column prop="purchaseDate" label="购置日期" align="center">
                </el-table-column>
                <el-table-column prop="manufacturer" label="生产厂家" align="center">
                </el-table-column>
                <el-table-column prop="whCodeName" label="所在库房" align="center" >
                </el-table-column>
                <el-table-column prop="location" label="保存地点" align="center" >
                </el-table-column>

                <el-table-column label="操作" align="center" width="180">
                    <template slot-scope="scope">
                        <el-button type="text" size="mini" title="详情" @click="handleDetail(scope.row)">详情</el-button>
                        <el-button type="text" size="mini" title="装备附件" @click="handleAnnex(scope.row)">装备附件</el-button>
                    </template>
                </el-table-column>
            </el-table>

            <!-- 分页 -->
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="deviceForm.pageNum" :page-sizes="[5, 10, 20, 50, 100]" :page-size="deviceForm.pageSize"
                layout="total, sizes, prev, pager, next, jumper" :total="total">
            </el-pagination>

        </el-card>
    </div>
</template>

<script>
import { searchDeviceData } from '@/api/device'
import { getDeviceClassByLevel } from '@/api/deviceClass.js'

export default {
    data() {
        return {
            deviceOneClassList: [],
            deviceList: [],
            deviceForm: {
                type: '00',
                deviceName: '',
                deviceType: '',
                deviceNum: '',
                deviceOneClass: '',
                pageNum: 1,
                pageSize: 10
            },
            total: 0,
            // //绑定条码的提交表单
            // formDevice: {
            //     id: '',
            //     deviceCode: ''
            // },
            // //绑定条码的弹框不显示
            // bindCodeView: false,
            // deviceIdShow: false,
            // keyDownTime: 0,
            // keyUpTime: 0,
            statusList: [{ status: '07', statusDese: '报废移除' }, { status: '08', statusDese: '借入归还' }, { status: '09', statusDese: '租赁移除' }],
            delForm: {  //删除设备
                id: '',
                status: null
            },
            delDeviceInfoView: false,
            formLabelWidth: '80px',
            DelRules: {
                status: [{ required: true, message: '请选择删除原因' }],
            }

        }
    },
    mounted() {

        this.loadDeviceOneClass()
        this.searchDeviceData()
    },
    methods: {

        handleSizeChange(val) {
            this.deviceForm.pageSize = val;
            this.deviceForm.pageNum = 1;
            this.searchDeviceData();
        },
        handleCurrentChange(val) {
            this.deviceForm.pageNum = val;
            this.searchDeviceData();
        },
        async loadDeviceOneClass() {
            const level = 1
            await getDeviceClassByLevel(level).then(res => {
                if (res.code === '000000') {
                    this.deviceOneClassList = res.t
                }

            })
        },

        //查询
        searchDeviceData() {
            searchDeviceData(this.deviceForm).then(res => {
                if (res.code === '000000') {
                    this.deviceList = res.t.list
                    this.total = res.t.total
                    let count = 1;
                    this.deviceList.forEach((item) => {
                        item.seq = count++
                        // item.userStatus ===1 ? (item.userStatus_text='启用') : (item.userStatus_text='停用')
                        // count= count +1;
                    })
                }

            })

        },

        //详情
        handleDetail({ id }) {
            this.$router.push({
                name: 'delete-detail',
                params: {
                    id
                }
            })
        },
        handleAnnex({ id }) {
            this.$router.push({
                name: 'delete-deviceAnnex',
                params: {
                    did: id
                }
            })
        },



        //搜索
        handleSearch() {
            this.searchDeviceData(this.deviceForm);
        },
        //重置
        handleReset() {
            this.deviceForm = {
                type: '00',
                deviceName: '',
                deviceType: '',
                deviceNum: '',
                deviceOneClass: '',
                pageNum: 1,
                pageSize: 10
            },
                this.searchDeviceData(this.deviceForm);
        },



    }
}

</script>


<style lang="scss" scoped>
.DeviceInfoList {

    .el-form-item,
    .el-pagination,
    .clearfix {
        text-align: left;
    }

    .delColor {
        color: red;
    }

    .delInfo {
        font-size: 16px;
        text-align: left;
        margin-bottom: 15px;
    }

}
</style>